var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"loan py-14 mdmax:px-4 mdmax:py-6"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"text-4xl font-bold mb-6 mdmax:text-2xl"},[_vm._v("Pinjaman Pendidikan")]),_vm._m(0),_c('div',{staticClass:"mdmax:overflow-x-auto"},[_c('div',{staticClass:"table-wrapper"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"flex gap-2 flex-grow w-full items-stretch mb-2"},[_c('div',{staticClass:"bg-grey flex-1 px-4 py-4 flex items-center"},[_vm._v("Dokumen Pendukung")]),_vm._m(6),_c('div',{staticClass:"bg-grey flex-1 px-4 py-4 flex flex-col items-start"},[_vm._m(7),_c('router-link',{staticClass:"text-white border border-yellow bg-yellow px-4 py-2 rounded font-semibold mt-4",attrs:{"to":"/loan/pintek"}},[_vm._v("Pelajari lebih lanjut")])],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-base mb-10"},[_c('p',[_vm._v(" Pinjaman pendidikan bertujuan untuk membantu mengurangi biaya investasi pembelajaran. Jangka waktu angsuran dan bunga tergantung pada penyedia pinjaman dan jumlah pinjaman itu sendiri. Pinjaman dapat dilakukan secara langsung atau menggunakan penjamin, jika peminjam berusia di bawah umur atau untuk mendukung proses pinjaman. ")]),_c('p',[_vm._v(" G2Academy menyediakan layanan angsuran bulanan melalui beberapa penyedia layanan. Untuk kenyamanan dalam memilih pinjaman yang paling sesuai, silakan merujuk ke tabel perbandingan di bawah ini. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex gap-2 flex-grow w-full items-stretch mb-2"},[_c('div',{staticClass:"flex-1 px-4 py-2 flex items-center"},[_vm._v("Fasilitas")]),_c('div',{staticClass:"flex-1 px-4 py-2 flex items-center justify-center"},[_c('img',{staticClass:"h-8",attrs:{"src":require("@/assets/images/logos/danacita.png")}})]),_c('div',{staticClass:"flex-1 px-4 py-2 flex items-center justify-center"},[_c('img',{staticClass:"h-8",attrs:{"src":require("@/assets/images/logos/pintek.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex gap-2 flex-grow w-full items-stretch mb-2"},[_c('div',{staticClass:"bg-grey flex-1 px-4 py-4 flex items-center"},[_vm._v("Jangka Waktu Peminjaman")]),_c('div',{staticClass:"bg-grey flex-1 px-4 py-4 flex items-center justify-center"},[_vm._v("12 bulan")]),_c('div',{staticClass:"bg-grey flex-1 px-4 py-4 flex items-center justify-center"},[_vm._v("2 - 12 bulan")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex gap-2 flex-grow w-full items-stretch mb-2"},[_c('div',{staticClass:"bg-grey flex-1 px-4 py-4 flex items-center"},[_vm._v("Biaya Platform Per Bulan (Bunga)")]),_c('div',{staticClass:"bg-grey flex-1 px-4 py-4 flex items-center justify-center"},[_vm._v("0%")]),_c('div',{staticClass:"bg-grey flex-1 px-4 py-4 flex items-center justify-center"},[_vm._v("0 * (syarat & ketentuan berlaku)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex gap-2 flex-grow w-full items-stretch mb-2"},[_c('div',{staticClass:"bg-grey flex-1 px-4 py-4 flex items-center"},[_vm._v("Biaya Persetujuan")]),_c('div',{staticClass:"bg-grey flex-1 px-4 py-4 flex items-center justify-center"},[_vm._v("3% , hanya 1 kali di depan")]),_c('div',{staticClass:"bg-grey flex-1 px-4 py-4 flex items-center justify-center"},[_vm._v("Rp. 100.000")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex gap-2 flex-grow w-full items-stretch mb-2"},[_c('div',{staticClass:"bg-grey flex-1 px-4 py-4 flex items-center"},[_vm._v("Biaya Administrasi")]),_c('div',{staticClass:"bg-grey flex-1 px-4 py-4 flex items-center justify-center"},[_vm._v("Tidak Ada")]),_c('div',{staticClass:"bg-grey flex-1 px-4 py-4 flex items-center justify-center"},[_vm._v("10% dari jumlah pinjaman")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-grey flex-1 px-4 py-4 flex flex-col items-start"},[_c('ol',{staticClass:"list-decimal ml-4"},[_c('li',[_vm._v("Foto KTP dan Selfie Pelajar")]),_c('li',[_vm._v("Foto KTP dan Selfie Wali (Pelajar di bawah usia 21 tahun, atau blm punya penghasilan wajib mengajukan bersama wali)")]),_c('li',[_vm._v("Bukti Tagihan G2 Academy")]),_c('li',[_vm._v("Slip Gaji/Rekening Koran 1 Bulan Terakhir (bila diperlukan)")])]),_c('a',{staticClass:"text-white border border-yellow bg-yellow px-4 py-2 rounded font-semibold mt-4",attrs:{"href":"https://danacita.co.id/partners/G2Academy/","target":"_blank"}},[_vm._v("Pelajari lebih lanjut")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',{staticClass:"list-decimal ml-4"},[_c('li',[_vm._v("KTP (peminjam dan penjamin jika ada)")]),_c('li',[_vm._v("KK")]),_c('li',[_vm._v("Bukti penghasilan selama 3 bulan terakhir (slip gaji dan rekening giro)")]),_c('li',[_vm._v("ID Elektronik")]),_c('li',[_vm._v("Selfie dengan KTP")]),_c('li',[_vm._v("NPWP (untuk peminjaman > 50 juta)")])])
}]

export { render, staticRenderFns }